import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage'; // Adicione esta linha

const firebaseConfig = {
  apiKey: "AIzaSyCKqi4WFrMCR88JgWxfvG94bgSa-OFweOM",
  authDomain: "isetup-eb690.firebaseapp.com",
  databaseURL: "https://isetup-eb690-default-rtdb.firebaseio.com",
  projectId: "isetup-eb690",
  storageBucket: "isetup-eb690.appspot.com",
  messagingSenderId: "503963303523",
  appId: "1:503963303523:web:b7f7610eae66403ddd0256"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  
  // Configurações adicionais para o auth
  const auth = firebase.auth();
  // Configura persistência
  auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  auth.settings.appVerificationDisabledForTesting = true;
  auth.useDeviceLanguage();
}

export const storage = firebase.storage(); // Adicione esta linha
export const auth = firebase.auth();
export const database = firebase.database();
export default firebase;