import React, { useMemo } from 'react';
import { Card } from '../ui/Card';
import { Alert, AlertDescription } from '../ui/alert';
import { Badge } from '../ui/badge';
import _ from 'lodash';

const RecommendationCard = ({
  title,
  priority,
  issue,
  suggestedActions,
  currentValue,
  targetValue,
  affects = [],
  tradeoffs = []
}) => (
  <Card className="bg-gray-800/50 p-4 mb-4">
    <div className="flex justify-between items-start mb-3">
      <div className="flex items-center gap-2">
        <h3 className="text-lg font-semibold text-white">{title}</h3>
        <Badge 
          variant={priority === 'high' ? 'destructive' : priority === 'medium' ? 'warning' : 'secondary'}
          className={`${
            priority === 'high' 
              ? 'bg-red-500/10 text-red-400 border-red-500/20' 
              : priority === 'medium'
                ? 'bg-yellow-500/10 text-yellow-400 border-yellow-500/20'
                : 'bg-blue-500/10 text-blue-400 border-blue-500/20'
          }`}
        >
          {priority}
        </Badge>
      </div>
    </div>

    <div className="space-y-3">
      <div className="text-gray-300">{issue}</div>

      {currentValue && targetValue && (
        <div className="flex items-center gap-4 bg-gray-900/30 p-2 rounded">
          <div>
            <div className="text-xs text-gray-400">Current</div>
            <div className="text-sm font-medium text-white">{currentValue}</div>
          </div>
          <div>
            <div className="text-xs text-gray-400">Target</div>
            <div className="text-sm font-medium text-green-400">{targetValue}</div>
          </div>
        </div>
      )}

      <div className="space-y-2">
        {suggestedActions.map((action, idx) => (
          <div key={idx} className="bg-gray-900/30 p-2 rounded flex items-start gap-2">
            <div>
              <p className="text-sm text-white">{action.text}</p>
              {action.impact && (
                <p className="text-xs text-gray-400 mt-1">Impact: {action.impact}</p>
              )}
            </div>
          </div>
        ))}
      </div>

      {affects.length > 0 && (
        <div className="mt-3">
          <div className="text-xs text-gray-400 mb-1">Areas affected:</div>
          <div className="flex flex-wrap gap-2">
            {affects.map((area, idx) => (
              <Badge key={idx} variant="outline" className="text-xs">
                {area}
              </Badge>
            ))}
          </div>
        </div>
      )}

      {tradeoffs.length > 0 && (
        <div className="mt-3">
          <div className="text-xs text-gray-400 mb-1">Trade-offs:</div>
          <div className="space-y-1">
            {tradeoffs.map((tradeoff, idx) => (
              <div key={idx} className="text-xs text-gray-500 flex items-center gap-1">
                {tradeoff}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  </Card>
);

const analyzeDampers = (damperData, setupData) => {
  const recommendations = [];

  if (!damperData || !setupData?.Dampers) return recommendations;

  const corners = ['FL', 'FR', 'RL', 'RR'];
  corners.forEach(corner => {
    const damper = damperData[corner];
    if (!damper?.velocity || !damper?.position) return;

    const velocities = damper.velocity.map(v => Math.abs(v.value));
    const positions = damper.position.map(p => p.value);
    const settings = corner.startsWith('F') ? setupData.Dampers.FrontDampers : setupData.Dampers.RearDampers;

    // Analyze low/high speed distribution
    const highSpeedPercentage = (velocities.filter(v => v > 1.5).length / velocities.length) * 100;
    const lowSpeedPercentage = (velocities.filter(v => v <= 1.5).length / velocities.length) * 100;
    
    // Check high-speed percentage (from shock tuning guide)
    if (highSpeedPercentage > 30) {
      recommendations.push({
        title: `${corner} High-Speed Movement`,
        priority: "high",
        issue: `${highSpeedPercentage.toFixed(1)}% of movement in high-speed range (>1.5 in/s)`,
        currentValue: settings?.HighSpeedCompressionDamping?.toString() || "N/A",
        targetValue: ((parseInt(settings?.HighSpeedCompressionDamping) || 0) + 2).toString(),
        suggestedActions: [{
          text: "Increase high-speed compression by 2-3 clicks",
          impact: "Reduce high-speed movement and improve stability"
        }],
        affects: ['Bump absorption', 'Platform stability'],
        tradeoffs: ['May reduce mechanical grip']
      });
    }

    // Position range analysis
    const positionRange = Math.max(...positions) - Math.min(...positions);
    if (positionRange > 30) { // More than 30mm travel
      recommendations.push({
        title: `${corner} Travel Range`,
        priority: "medium",
        issue: `Excessive suspension travel of ${positionRange.toFixed(1)}mm`,
        suggestedActions: [{
          text: "Increase spring rate by ~10%",
          impact: "Reduce suspension movement"
        }],
        affects: ['Ride height consistency', 'Aero platform'],
        tradeoffs: ['May require damper retuning']
      });
    }
  });

  return recommendations;
};

const analyzeRideHeights = (telemetryData, setupData) => {
  const recommendations = [];
  
  if (!telemetryData?.laps?.[0]?.rideHeights) return recommendations;

  const rideHeights = telemetryData.laps[0].rideHeights;
  const corners = ['LF', 'RF', 'LR', 'RR'];

  // Calculate average ride heights
  const avgHeights = {};
  corners.forEach(corner => {
    if (rideHeights[corner]) {
      avgHeights[corner] = _.mean(rideHeights[corner].map(h => h.value));
    }
  });

  // Check front-to-rear rake
  const frontAvg = (avgHeights.LF + avgHeights.RF) / 2;
  const rearAvg = (avgHeights.LR + avgHeights.RR) / 2;
  const rake = rearAvg - frontAvg;

  if (Math.abs(rake - 0.01) > 0.005) { // Target ~10mm rake
    recommendations.push({
      title: "Rake Adjustment",
      priority: "high",
      issue: `Current rake of ${(rake * 1000).toFixed(1)}mm needs optimization`,
      currentValue: `${(rake * 1000).toFixed(1)}mm`,
      targetValue: "10mm",
      suggestedActions: [{
        text: rake > 0.015 
          ? "Lower rear ride height by 2-3mm" 
          : "Raise rear ride height by 2-3mm",
        impact: "Optimize aero balance"
      }],
      affects: ['Aerodynamic balance', 'Stability'],
      tradeoffs: ['May require damper retuning']
    });
  }

  return recommendations;
};

const analyzeWheelSpeeds = (telemetryData, setupData) => {
  const recommendations = [];

  if (!telemetryData?.laps?.[0]?.wheelSpeeds) return recommendations;

  const wheelSpeeds = telemetryData.laps[0].wheelSpeeds;
  
  // Analyze traction loss
  ['Left', 'Right'].forEach(side => {
    const frontSpeeds = wheelSpeeds[`${side[0]}F`]?.map(s => s.value) || [];
    const rearSpeeds = wheelSpeeds[`${side[0]}R`]?.map(s => s.value) || [];

    if (frontSpeeds.length === rearSpeeds.length && frontSpeeds.length > 0) {
      const speedDiffs = rearSpeeds.map((rs, i) => rs - frontSpeeds[i]);
      const maxSpinDiff = Math.max(...speedDiffs);

      if (maxSpinDiff > 2) {
        recommendations.push({
          title: `${side} Side Traction`,
          priority: "high",
          issue: `Excessive wheelspin detected (${maxSpinDiff.toFixed(1)} m/s difference)`,
          suggestedActions: [{
            text: "Reduce rear rebound damping by 1-2 clicks",
            impact: "Improve traction"
          }, {
            text: "Consider rear wing angle increase",
            impact: "Additional rear downforce"
          }],
          affects: ['Corner exit traction', 'Acceleration'],
          tradeoffs: ['May affect turn-in response']
        });
      }
    }
  });

  return recommendations;
};

const analyzeSpringRates = (telemetryData, setupData, damperData) => {
    const recommendations = [];
  
    // Verifica se temos dados válidos
    if (!damperData || !setupData?.Chassis) return recommendations;
  
    const chassis = setupData.Chassis;
    const springCorners = ['LeftFront', 'RightFront', 'LeftRear', 'RightRear'];
  
    springCorners.forEach(corner => {
      // Obtém spring rate atual do setup
      const springRate = chassis[corner]?.SpringRate;
      if (!springRate) return;
  
      // Mapeia nome do corner para o formato do damperData
      const cornerShort = {
        'LeftFront': 'FL',
        'RightFront': 'FR', 
        'LeftRear': 'RL',
        'RightRear': 'RR'
      }[corner];
  
      // Obtém dados de movimento do damper da telemetria
      const damperPositions = damperData[cornerShort]?.position;
      if (!damperPositions?.length) return;
  
      const positions = damperPositions.map(p => p.value);
      const positionRange = Math.max(...positions) - Math.min(...positions);
  
      // Analisa se o movimento é excessivo
      if (positionRange > 30) { // 30mm threshold
        let recommendedSpringRate;
        if (positionRange > 45) {
          recommendedSpringRate = Math.round(springRate + (springRate * 0.15)); // 15% para movimentos muito grandes
        } else {
          recommendedSpringRate = Math.round(springRate + (springRate * 0.10)); // 10% para movimentos moderados
        }
  
        recommendations.push({
          title: `${cornerShort} Spring Rate`,
          priority: "medium",
          issue: `Excessive suspension travel of ${positionRange.toFixed(1)}mm`,
          currentValue: `${springRate} N/mm`,
          targetValue: `${recommendedSpringRate} N/mm`,
          suggestedActions: [{
            text: `Increase spring rate from ${springRate} N/mm to ${recommendedSpringRate} N/mm`,
            impact: "Reduce suspension movement and improve platform stability"
          }],
          affects: ['Ride height consistency', 'Aero platform'],
          tradeoffs: ['Will require damper retuning', 'May reduce mechanical grip over bumps']
        });
      }
    });
  
    return recommendations;
  };

const analyzeAero = (telemetryData, setupData) => {
  const recommendations = [];

  if (!setupData?.TiresAero?.AeroBalanceCalc) return recommendations;

  const aero = setupData.TiresAero.AeroBalanceCalc;
  
  // Analyze wing settings based on track speed
  const speeds = telemetryData?.laps?.[0]?.speed?.map(s => s.value) || [];
  if (speeds.length > 0) {
    const avgSpeed = _.mean(speeds);
    const maxSpeed = Math.max(...speeds);
    
    if (maxSpeed > 250 && aero.RearWingAngle < 8) {
      recommendations.push({
        title: "Rear Wing Setting",
        priority: "medium",
        issue: "Wing angle may be too low for track speeds",
        currentValue: `${aero.RearWingAngle}°`,
        targetValue: `${Math.min(aero.RearWingAngle + 2, 12)}°`,
        suggestedActions: [{
          text: "Increase rear wing angle by 1-2°",
          impact: "Better high-speed stability"
        }],
        affects: ['High-speed stability', 'Braking stability'],
        tradeoffs: ['Increased drag']
      });
    }
  }

  return recommendations;
};

const SetupRecommendations = ({ 
  telemetryData, 
  setupData, 
  damperData,
  sessionInfo 
}) => {
  const recommendations = useMemo(() => {
    if (!telemetryData || !setupData || !damperData) return [];

    return [
      ...analyzeDampers(damperData, setupData),
      ...analyzeRideHeights(telemetryData, setupData),
      ...analyzeWheelSpeeds(telemetryData, setupData),
      ...analyzeSpringRates(telemetryData, setupData),
      ...analyzeAero(telemetryData, setupData)
    ].sort((a, b) => {
      const priorityWeight = { high: 3, medium: 2, low: 1 };
      return priorityWeight[b.priority] - priorityWeight[a.priority];
    });
  }, [telemetryData, setupData, damperData]);

  if (!recommendations.length) {
    return (
      <Alert>
        <AlertDescription>
          No setup changes recommended based on current telemetry data.
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="space-y-4">
      {recommendations.map((rec, index) => (
        <RecommendationCard key={index} {...rec} />
      ))}
    </div>
  );
};

export default SetupRecommendations;