// src/App.jsx
import React, { useState, useEffect } from 'react';
import { useAuth } from './context/AuthContext';
import { HelmetProvider } from 'react-helmet-async';
import SEO from './components/SEO';
import { PAGE_SEO } from './components/SEO/pageSEO';
import LoginForm from './components/auth/LoginForm';
import RegisterForm from './components/auth/RegisterForm';
import SubscriptionRenewalModal from './components/auth/SubscriptionRenewalModal';
import Header from './components/layout/Header';
import SetupFlow from './components/setup/SetupFlow';
import TelemetryDashboard from './components/telemetry/TelemetryDashboard';
import AdminPanel from './components/admin/AdminPanel';
import DownloadsPage from './components/downloads/DownloadsPage'; // Verifique se o caminho está correto
import AdminSetupsPanel from './components/downloads/AdminSetupsPanel'; // Verifique se o caminho está correto

import { authService } from './services/authService';

function App() {
  const { user, loading, subscription, isAdmin } = useAuth();
  const [showLogin, setShowLogin] = useState(true);
  const [activeTab, setActiveTab] = useState('setup');

  useEffect(() => {
    if (user && !isAdmin) {
      authService.startSubscriptionMonitoring(user.uid);
    }
    return () => authService.stopSubscriptionMonitoring();
  }, [user, isAdmin]);

  const getCurrentSEO = () => {
    if (!user) {
      return showLogin ? PAGE_SEO.login : PAGE_SEO.register;
    }
    switch (activeTab) {
      case 'telemetry':
        return PAGE_SEO.telemetry;
      case 'admin':
        return PAGE_SEO.admin;
      case 'setup':
      default:
        return PAGE_SEO.setup;
    }
  };

  if (loading) {
    return (
      <HelmetProvider>
        <SEO {...PAGE_SEO.home} />
        <div className="bg-gray-900 min-h-screen flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      </HelmetProvider>
    );
  }

  if (!user) {
    return (
      <HelmetProvider>
        <SEO {...(showLogin ? PAGE_SEO.login : PAGE_SEO.register)} />
        <div className="bg-gray-900 min-h-screen flex items-center justify-center">
          <div id="auth-container" className="w-full max-w-md">
            {showLogin ? (
              <LoginForm onSwitchToRegister={() => setShowLogin(false)} />
            ) : (
              <RegisterForm onSwitchToLogin={() => setShowLogin(true)} />
            )}
          </div>
        </div>
      </HelmetProvider>
    );
  }

  if (!isAdmin && subscription?.needsRenewal) {
    return (
      <HelmetProvider>
        <SEO {...PAGE_SEO.home} />
        <SubscriptionRenewalModal />
      </HelmetProvider>
    );
  }

  const renderLogoSection = () => (
    <div className="text-center mb-12">
      <div className="logo-container">
        <img src="/assets/logo_isetup1.png" alt="iSetup" className="logo-image mx-auto" />
        <div className="logo-divider"></div>
      </div>
    </div>
  );

  return (
    <HelmetProvider>
      <SEO {...getCurrentSEO()} />
      <div className="bg-gray-900">
        <Header onTabChange={setActiveTab} />
        
        {/* Painel Admin */}
        <div id="admin-panel" className={activeTab === 'admin' ? 'block' : 'hidden'}>
  <div className="container mx-auto px-4">
    {renderLogoSection()}
    <div className="space-y-8">
      <AdminPanel /> {/* Painel existente de códigos */}
      <AdminSetupsPanel /> {/* Novo painel de setups */}
    </div>
  </div>
</div>
        
        {/* Conteúdo Principal (Setup) */}
        <main id="main-content" style={{ display: activeTab === 'setup' ? 'block' : 'none' }} className="container mx-auto px-4">
          {renderLogoSection()}
          <SetupFlow />
        </main>

        {/* Conteúdo da Telemetria */}
        <div id="telemetry-content" style={{ display: activeTab === 'telemetry' ? 'block' : 'none' }} className="px-4">
          {renderLogoSection()}
          <TelemetryDashboard />
        </div>
      </div>

      <div id="downloads-panel" style={{ display: activeTab === 'downloads' ? 'block' : 'none' }}>
  {console.log('App - Renderizando downloads, activeTab:', activeTab)} {/* Debug */}
  <div className="container mx-auto px-4">
    {renderLogoSection()}
    <DownloadsPage />
  </div>
</div>

    </HelmetProvider>

  
  );
}
export default App;