// chartConfigs.js
export const CHART_CONFIGS = {
    speed: {
      label: 'SPEED',
      unit: 'km/h',
      height: 230,
      domain: [0, 320],
      format: (value) => `${value?.toFixed(0) || 0}km/h`,
      gridLines: 4,
      showLabel: true
    },
    throttle: {
      label: 'THROTTLE',
      unit: '%',
      height: 140,
      domain: [0, 100],
      format: (value) => `${Math.round(value || 0)}%`,
      gridLines: 2,
      showLabel: true
    },
    brake: {
      label: 'BRAKE',
      unit: '%',
      height: 200,
      domain: [0, 100],
      format: (value) => `${Math.round(value || 0)}%`,
      gridLines: 2,
      showLabel: true
    },
    gear: {
      label: 'GEAR',
      height: 120,
      domain: [-1, 7],
      format: (value) => {
        if (value === -1) return 'R';
        if (value === 0) return 'N';
        return Math.round(value || 0).toString();
      },
      gridLines: 10,
      showLabel: true,
      yAxisTicks: [-1, 0, 1, 2, 3, 4, 5, 6, 7]
    },
    steering: {
      label: 'STEERING',
      unit: 'deg',
      height: 150,
      domain: [-180, 180],
      format: (value) => `${(value || 0).toFixed(1)}°`,
      gridLines: 3,
      centerLine: true,
      showLabel: true
    },
    racingLine: {
      label: 'RACING LINE DIFFERENCE',
      unit: 'm',
      height: 190,
      domain: [-4, 4],
      format: (value) => {
        if (!value || Math.abs(value) < 0.01) return 'Center';
        return value > 0 ? `${value.toFixed(2)}L` : `${Math.abs(value).toFixed(2)}R`;
      },
      gridLines: 5,
      centerLine: true,
      showLabel: true,
      symmetrical: true
    },
    lapDelta: {
      label: 'LAP TIME DELTA',
      unit: 's',
      height: 300,
      domain: [-1, 1],
      format: (value) => {
        if (!value) return '0.000s';
        return `${value > 0 ? '+' : ''}${value.toFixed(3)}s`;
      },
      gridLines: 4,
      centerLine: true,
      showLabel: true,
      showLegend: true,
      symmetrical: true
    }
  };