// TelemetryAnalyzer.jsx - Part 1/5 - Imports e Constantes
import React, { useState, useCallback, useMemo,useRef,useEffect} from 'react';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer,
  ReferenceLine 
} from 'recharts';
import TrackMap from './TrackMap';
import { trackOverlay } from './trackOverlay.js';
import createIbtParser from '../../services/ibtParser';
import RecommendationsTab from './RecommendationsTab';
import SetupTab from './SetupTab';
import ShocksTab from './ShocksTab';
import { CHART_CONFIGS } from './chartConfigs';

// Color constants
const LAP_COLORS = {
  current: '#00C8FF',    // Cyan brilhante
  compare1: '#FF00DD',   // Magenta brilhante
  compare2: '#00FF66',   // Verde brilhante
  compare3: '#FFCC00'    // Amarelo brilhante
};

// Utility functions
const formatLapTime = (timeInSeconds) => {
  if (!timeInSeconds) return '--:--';
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return `${minutes}:${seconds.toFixed(3).padStart(6, '0')}`;
};

const formatDeltaTime = (deltaSeconds) => {
  if (!deltaSeconds && deltaSeconds !== 0) return '--';
  const sign = deltaSeconds >= 0 ? '+' : '';
  return `${sign}${deltaSeconds.toFixed(3)}`;
};

const formatDistance = (meters) => {
  if (meters >= 1000) {
    const km = meters / 1000;
    if (km < 10) return `${km.toFixed(1)}km`;
    return `${Math.round(km)}km`;
  }
  return `${Math.round(meters/100)*100}m`;
};

const isValidLapTime = (time) => {
  return time && time > 60 && time < 600; // entre 1 min e 10 min
};

// TelemetryAnalyzer.jsx - Part 2/5 - UI Components

// LapCheckbox Component
const LapCheckbox = React.memo(({ checked, color, onChange, disabled }) => (
  <div 
    className={`
      w-4 h-4 border rounded cursor-pointer transition-colors
      ${checked ? 'bg-opacity-50' : 'bg-opacity-0'}
      ${disabled ? 'cursor-not-allowed opacity-50' : 'hover:bg-opacity-25'}
    `}
    style={{ 
      backgroundColor: checked ? color : 'transparent',
      borderColor: color
    }}
    onClick={() => !disabled && onChange()}
  />
));


const LapList = React.memo(({ 
  mainTelemetry, 
  comparisonTelemetry, 
  selectedLap,
  comparisonLaps,
  onLapSelect,
  onComparisonToggle,
  fastestLapIndex
}) => {
  const renderTelemetryHeader = (telemetry) => {
    const sessionInfo = telemetry?.sessionInfo;
    const driver = sessionInfo?.DriverInfo?.TelemetryOwner;
    const session = telemetry?.sessionInfo?.session;
  
    return (
      <div className="mb-4 bg-gray-800/30 rounded p-3">
        <div className="text-sm font-medium text-gray-200">
          {session?.carName || 'Unknown Car'}
        </div>
        <div className="flex items-center justify-between mt-1">
          <span className="text-xs text-gray-400">
            {session?.trackName} {session?.trackConfig ? `(${session?.trackConfig})` : ''}
          </span>
          <span className="text-xs bg-gray-700/50 px-2 py-0.5 rounded">
            {session?.type || 'Unknown Session'}
          </span>
        </div>
        <div className="text-xs text-gray-500 mt-1">
          {driver?.UserName || 'Unknown Driver'}
        </div>
      </div>
    );
  };

  // Função para renderizar uma lista de voltas
  const renderLaps = (laps, isComparison = false, bestLapIndex = -1) => {
    return laps?.map((lap, index) => {
      const isBestLap = index === bestLapIndex;
      const delta = lap.time && laps[bestLapIndex]?.time 
        ? lap.time - laps[bestLapIndex].time 
        : null;
      const isCompared = comparisonLaps.some(c => c.lapNumber === index + 1 && c.isComparison === isComparison);
      const compareColor = isCompared 
        ? LAP_COLORS[`compare${comparisonLaps.findIndex(c => c.lapNumber === index + 1) + 1}`]
        : undefined;
      
      return (
        <div 
          key={`${isComparison ? 'comp-' : ''}${index}`}
          className={`
            flex items-center gap-3 p-2 rounded
            ${selectedLap === index && !isComparison ? 'bg-blue-500/20' : 'hover:bg-gray-800/50'}
            ${isBestLap ? 'border-l-2 border-purple-400' : ''}
          `}
        >
          <div className="flex gap-1">
            <LapCheckbox
              checked={selectedLap === index && !isComparison}
              color={LAP_COLORS.current}
              onChange={() => onLapSelect(index, isComparison)}
              disabled={isCompared || isComparison}
            />
            <LapCheckbox
              checked={isCompared}
              color={compareColor || LAP_COLORS.compare1}
              onChange={() => onComparisonToggle(index, isComparison)}
              disabled={selectedLap === index || 
                       (!isCompared && comparisonLaps.length >= 3)}
            />
          </div>

          <div className="flex-1 flex items-center justify-between">
            <div className="flex items-center gap-3">
              <span className="text-sm text-gray-400">L{index + 1}</span>
              <span className="text-sm font-medium text-gray-200">
                {isValidLapTime(lap.time) ? formatLapTime(lap.time) : '--:--'}
              </span>
              {isBestLap && (
                <span className="text-xs text-purple-400">★ BEST</span>
              )}
            </div>
            {delta !== null && isValidLapTime(lap.time) && (
              <span className={`text-sm ${
                delta === 0 ? 'text-purple-400' : 
                delta < 0 ? 'text-green-400' : 'text-red-400'
              }`}>
                {formatDeltaTime(delta)}
              </span>
            )}
          </div>
        </div>
      );
    }) || null;
  };

  // Encontra a volta mais rápida para cada telemetria
  const findFastestLap = (laps) => {
    if (!laps?.length) return -1;
    let fastestIndex = 0;
    let fastestTime = Infinity;

    laps.forEach((lap, index) => {
      if (isValidLapTime(lap.time) && lap.time < fastestTime) {
        fastestTime = lap.time;
        fastestIndex = index;
      }
    });

    return fastestIndex;
  };

  return (
    <div className="space-y-6">
      {/* Telemetria Principal */}
      <div>
        {renderTelemetryHeader(mainTelemetry)}
        <div className="space-y-1">
          {renderLaps(mainTelemetry?.laps, false, findFastestLap(mainTelemetry?.laps))}
        </div>
      </div>

      {/* Separador */}
      {comparisonTelemetry && (
        <div className="border-t border-gray-700/30 my-4" />
      )}

      {/* Telemetria de Comparação */}
      {comparisonTelemetry && (
        <div>
          {renderTelemetryHeader(comparisonTelemetry)}
          <div className="space-y-1">
            {renderLaps(comparisonTelemetry?.laps, true, findFastestLap(comparisonTelemetry?.laps))}
          </div>
        </div>
      )}
    </div>
  );
});

// Session Info Component - Novo componente para mostrar informações da sessão
// Session Info Component - Com botão de upload

const SessionInfo = React.memo(({ data, onComparisonLoad }) => {
  const sessionInfo = data?.sessionInfo?.session || {};
  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    if (!file.name.endsWith('.ibt')) {
      alert('Por favor, selecione um arquivo .ibt válido');
      return;
    }

    try {
      const parser = new createIbtParser();
      parser.setDebug(true);
      const result = await parser.parseFile(file);

      if (!result) {
        throw new Error('Falha ao processar arquivo de telemetria');
      }

      // Passa o resultado processado para o componente pai
      onComparisonLoad(result);
      

    } catch (error) {
      console.error('Erro ao processar arquivo:', error);
      alert('Erro ao processar arquivo de telemetria');
    }

    event.target.value = '';
  };


  return (
    <div className="p-4 border-b border-gray-800/30 space-y-3">
      <div className="flex items-center justify-between">
        <div>
          <div className="text-sm font-medium text-gray-200">
            {sessionInfo.carName || 'Unknown Car'}
          </div>
          <div className="text-xs text-gray-400">
            {sessionInfo.trackName || 'Unknown Track'}
          </div>
        </div>
        
        <div>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept=".ibt"
            className="hidden"
          />
          <button
            onClick={() => fileInputRef.current?.click()}
            className="bg-purple-500/20 hover:bg-purple-500/30 text-purple-300 px-3 py-1.5 rounded text-xs font-medium flex items-center gap-2 transition-colors"
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="w-4 h-4" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
              <polyline points="17 8 12 3 7 8" />
              <line x1="12" y1="3" x2="12" y2="15" />
            </svg>
            Compare Telemetry
          </button>
        </div>
      </div>
      
      <div className="grid grid-cols-2 gap-2 text-xs text-gray-400">
        <div>Distance: {formatDistance(sessionInfo.trackLength * 1000)}</div>
        <div>Type: {sessionInfo.type}</div>
      </div>
    </div>
  );
});
// TelemetryAnalyzer.jsx - Part 3/5 - TelemetryChart Component

// TelemetryChart Component
const TelemetryChart = React.memo(({
  data = [],
  comparisonData = [],
  dataKey,
  config = {},
  onHover,
  onMouseLeave
}) => {
  const [activePointIndex, setActivePointIndex] = useState(null);

  // Modificação no handler de hover para capturar todos os detalhes
  const handleMouseMove = useCallback((state, e) => {  
    if (state?.activeTooltipIndex !== undefined) {
      setActivePointIndex(state.activeTooltipIndex);
      onHover?.(state.activeTooltipIndex);
    }
  }, [onHover]);

  const handleMouseLeave = useCallback(() => {
    setActivePointIndex(null);
    onHover?.(null);
    onMouseLeave?.();
  }, [onHover, onMouseLeave]);

  // Função segura para obter valor do ponto
  const getPointValue = useCallback((pointData, key) => {
    if (!pointData) return null;
    return pointData[key] ?? null;
  }, []);

  // Tooltip personalizado com mais informações
  const CustomTooltip = ({ active, payload }) => {
    if (!active || activePointIndex === null || !data[activePointIndex]) return null;

    const currentPoint = data[activePointIndex];
    if (!currentPoint) return null;

    return (
      <div className="bg-gray-900/95 backdrop-blur-sm border border-gray-800 rounded px-3 py-2">
        {/* Seção principal com distância */}
        <div className="text-xs text-gray-400 mb-1">
          Distance: {formatDistance(currentPoint.distance)}
        </div>
        
        {/* Dados da volta principal */}
        <div className="flex items-center gap-2 whitespace-nowrap">
          <div 
            className="w-2 h-2 rounded-full" 
            style={{ backgroundColor: LAP_COLORS.current }} 
          />
          <span className="text-gray-400 text-xs">Current:</span>
          <span 
            className="font-medium text-xs" 
            style={{ color: LAP_COLORS.current }}
          >
            {config.format(getPointValue(currentPoint, dataKey))}
          </span>
        </div>

        {/* Dados das voltas de comparação */}
        {comparisonData.map((comparison, index) => {
          const compPoint = comparison?.data?.[activePointIndex];
          if (!compPoint) return null;

          const value = getPointValue(compPoint, dataKey);
          const diff = value - getPointValue(currentPoint, dataKey);

          return (
            <div key={comparison.lapNumber} className="flex items-center gap-2 whitespace-nowrap">
              <div 
                className="w-2 h-2 rounded-full" 
                style={{ backgroundColor: LAP_COLORS[`compare${index + 1}`] }} 
              />
              <span className="text-gray-400 text-xs">
                Lap {comparison.lapNumber}:
              </span>
              <span 
                className="font-medium text-xs" 
                style={{ color: LAP_COLORS[`compare${index + 1}`] }}
              >
                {config.format(value)}
              </span>
              {diff !== 0 && (
                <span className={`text-xs ${diff > 0 ? 'text-green-400' : 'text-red-400'}`}>
                  ({diff > 0 ? '+' : ''}{diff.toFixed(1)})
                </span>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  // Se não houver dados, mostra mensagem
  if (!data.length) {
    return (
      <div className="h-full flex items-center justify-center text-gray-500">
        No data available
      </div>
    );
  }

  return (
    <div 
      className="relative w-full bg-gray-900/30 rounded border border-gray-800/30"
      style={{ height: config.height }}
    >
      {/* Cabeçalho do gráfico */}
      <div className="absolute top-0 left-0 right-0 h-8 px-3 py-1 flex justify-between items-center bg-gray-800/50 rounded-t">
        <div className="flex items-center gap-2">
          <span className="text-xs font-semibold text-gray-300">{config.label}</span>
          {config.unit && (
            <span className="text-xs text-gray-500">[{config.unit}]</span>
          )}
        </div>
      </div>

      {/* Área do gráfico */}
      <div className="mt-8" style={{ height: config.height - 8 }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{ top: 5, right: 30, bottom: 5, left: 40 }}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          >
            <CartesianGrid 
              stroke="rgba(255, 255, 255, 0.03)"
              vertical={false}
            />

            <XAxis
              dataKey="distance"
              type="number"
              domain={['dataMin', 'dataMax']}
              tick={{ fill: '#94a3b8', fontSize: 10 }}
              tickFormatter={formatDistance}
            />

            <YAxis
              domain={config.domain}
              ticks={config.yAxisTicks}
              tick={{ fill: '#94a3b8', fontSize: 10 }}
              tickFormatter={config.format}
              width={40}
            />

            {config.centerLine && (
              <ReferenceLine 
                y={0} 
                stroke="rgba(255,255,255,0.1)"
                strokeDasharray="3 3"
              />
            )}

            {/* Linha principal */}
            <Line
              type="monotone"
              dataKey={dataKey}
              stroke={LAP_COLORS.current}
              strokeWidth={1.5}
              dot={false}
              isAnimationActive={false}
              connectNulls
              activeDot={{ r: 4, fill: LAP_COLORS.current }}
            />

            {/* Linhas de comparação */}
            {comparisonData.map((comparison, index) => (
              comparison?.data?.length > 0 && (
                <Line
                  key={`compare-${index}`}
                  type="monotone"
                  data={comparison.data}
                  dataKey={dataKey}
                  name={`Lap ${comparison.lapNumber}`}
                  stroke={LAP_COLORS[`compare${index + 1}`]}
                  strokeWidth={1.5}
                  dot={false}
                  isAnimationActive={false}
                  connectNulls
                  activeDot={{ r: 3, fill: LAP_COLORS[`compare${index + 1}`] }}
                />
              )
            ))}

            {/* Cursor vertical único */}
            {activePointIndex !== null && data[activePointIndex] && (
              <ReferenceLine
                x={data[activePointIndex].distance}
                stroke="rgba(255,255,255,0.2)"
                strokeDasharray="3 3"
              />
            )}

            <Tooltip
              content={<CustomTooltip />}
              cursor={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
});// TelemetryAnalyzer.jsx - Part 4/5 - Data Processing Functions

const normalizeLapData = (lapData) => {
  if (!lapData?.speed?.length) return [];
  
  const totalDistance = lapData.speed[lapData.speed.length - 1].distance;
  
  // Cria pontos normalizados em intervalos regulares
  const normalizedPoints = [];
  const interval = totalDistance / 1000; // 1000 pontos por volta para suavidade
  
  for (let distance = 0; distance <= totalDistance; distance += interval) {
    // Encontra os pontos mais próximos para interpolação
    let beforeIndex = 0;
    let afterIndex = 1;
    
    for (let i = 0; i < lapData.speed.length - 1; i++) {
      if (lapData.speed[i].distance <= distance && lapData.speed[i + 1].distance > distance) {
        beforeIndex = i;
        afterIndex = i + 1;
        break;
      }
    }
    
    const beforePoint = lapData.speed[beforeIndex];
    const afterPoint = lapData.speed[afterIndex];
    
    // Interpolação linear para todos os valores
    const factor = (distance - beforePoint.distance) / (afterPoint.distance - beforePoint.distance) || 0;
    
    const beforeInput = lapData.inputs?.[beforeIndex] || {};
    const afterInput = lapData.inputs?.[afterIndex] || {};
    
    // Interpolação da posição na pista e outros valores
    const beforePos = beforeInput.LapDistPct || 0;
    const afterPos = afterInput.LapDistPct || 0;
    const currentPos = beforePos + (afterPos - beforePos) * factor;

    // Interpolação da posição lateral do carro
    const beforeLat = beforeInput.LateralPosition || 0;
    const afterLat = afterInput.LateralPosition || 0;
    const lateralPos = beforeLat + (afterLat - beforeLat) * factor;

    // Cálculo de velocidade em curva
    const speed = beforePoint.value + (afterPoint.value - beforePoint.value) * factor;
    const beforeSpeed = beforePoint.value;
    const afterSpeed = afterPoint.value;
    const speedDiff = afterSpeed - beforeSpeed;

    normalizedPoints.push({
      distance,
      speed,
      speedDiff,
      gear: Math.round(beforeInput.gear + (afterInput.gear - beforeInput.gear) * factor) || 0,
      throttle: (beforeInput.throttle + (afterInput.throttle - beforeInput.throttle) * factor) * 100,
      brake: (beforeInput.brake + (afterInput.brake - beforeInput.brake) * factor) * 100,
      steering: (beforeInput.steering + (afterInput.steering - beforeInput.steering) * factor) * 57.2958,
      sessionTime: beforePoint.sessionTime + (afterPoint.sessionTime - beforePoint.sessionTime) * factor,
      trackPosition: currentPos,
      lateralPosition: lateralPos,
      LapDistPct: currentPos
    });
  }
  
  return normalizedPoints;
};

// TelemetryAnalyzer.jsx - Part 5/5 - Main Component

const TelemetryAnalyzer = ({ data }) => {
  // Estados principais
  const [selectedLap, setSelectedLap] = useState(0);
  const [comparisonLaps, setComparisonLaps] = useState([]);
  const [hoverPointIndex, setHoverPointIndex] = useState(null);
  const [allLaps, setAllLaps] = useState([]); // Novo estado para todas as voltas
  const [selectedTelemetry, setSelectedTelemetry] = useState('main'); // 'main' ou 'comparison'
  const [comparisonTelemetry, setComparisonTelemetry] = useState(null);
  const [activeTab, setActiveTab] = useState('general');

  const fastestLapIndex = useMemo(() => {
    if (!data?.laps?.length) return -1;

    let fastestIndex = 0;
    let fastestTime = Infinity;

    data.laps.forEach((lap, index) => {
      if (isValidLapTime(lap.time) && lap.time < fastestTime) {
        fastestTime = lap.time;
        fastestIndex = index;
      }
    });

    return fastestIndex;
  }, [data?.laps]);

  const comparisonFastestLapIndex = useMemo(() => {
    if (!comparisonTelemetry?.laps?.length) return -1;

    let fastestIndex = 0;
    let fastestTime = Infinity;

    comparisonTelemetry.laps.forEach((lap, index) => {
      if (isValidLapTime(lap.time) && lap.time < fastestTime) {
        fastestTime = lap.time;
        fastestIndex = index;
      }
    });

    return fastestIndex;
  }, [comparisonTelemetry?.laps]);


  useEffect(() => {
    if (data?.laps) {
      setAllLaps(data.laps);
    }
  }, [data]);
  const handleComparisonLoad = useCallback(async (newTelemetry) => {
    setComparisonTelemetry(newTelemetry);
    // Reset dos estados de comparação quando uma nova telemetria é carregada
    setComparisonLaps([]);
  }, []);

  // Verificação de dados válidos
  const hasValidData = useMemo(() => {
    return data && 
           data.laps && 
           Array.isArray(data.laps) && 
           data.laps.length > 0 && 
           selectedLap < data.laps.length;
  }, [data, selectedLap]);

const processedData = useMemo(() => {
  if (!hasValidData) return [];
  
  const currentLap = data.laps[selectedLap];
  const normalizedCurrent = normalizeLapData(currentLap);

  if (comparisonLaps.length === 0) {
    return normalizedCurrent.map(point => ({
      ...point,
      lineDiff: 0,
      timeDelta: 0,
      speedDiff: 0
    }));
  }

  const currentStartTime = currentLap.speed[0].sessionTime;

  // Processa os dados da volta atual
  return normalizedCurrent.map((point, i) => {
    const currentTime = point.sessionTime - currentStartTime;
    const currentInput = currentLap.inputs?.[i];
    const steering = currentInput?.steering || 0;
    
    // Racing line: usa steering para posição lateral
    const normalizedTrackPos = Math.sin(steering) * 2;
    
    return {
      ...point,
      currentTime,
      normalizedTrackPos,
      timeDelta: 0,
      lineDiff: 0,
      speedDiff: 0
    };
  });
}, [data?.laps, selectedLap, comparisonLaps, hasValidData]);

// Processa os dados das voltas de comparação
const processedComparisonData = useMemo(() => {
  if (!hasValidData || comparisonLaps.length === 0) return [];

  return comparisonLaps.map(comparison => {
    const comparisonLap = comparison.isComparison ? 
      comparisonTelemetry?.laps[comparison.lapNumber - 1] : 
      data.laps[comparison.lapNumber - 1];

    if (!comparisonLap) return null;

    const normalizedComparison = normalizeLapData(comparisonLap);
    const comparisonStartTime = comparisonLap.speed[0].sessionTime;

    const comparisonData = normalizedComparison.map((point, i) => {
      const compInput = comparisonLap.inputs?.[i];
      const compSteering = compInput?.steering || 0;
      const currentPoint = processedData[i];
      
      // Racing line calculation
      const compTrackPos = Math.sin(compSteering) * 2;
      const currentTrackPos = currentPoint?.normalizedTrackPos || 0;
      const lineDiff = compTrackPos - currentTrackPos;

      // Time delta calculation (mantendo a lógica original)
      const comparisonTime = point.sessionTime - comparisonStartTime;
      const timeDelta = currentPoint ? comparisonTime - currentPoint.currentTime : 0;

      return {
        ...point,
        timeDelta,
        lineDiff,
        normalizedTrackPos: compTrackPos,
        speedDiff: currentPoint ? point.speed - currentPoint.speed : 0
      };
    });

    return {
      lapNumber: comparison.lapNumber,
      data: comparisonData
    };
  }).filter(Boolean);
}, [data?.laps, comparisonLaps, comparisonTelemetry?.laps, hasValidData, processedData]);

  // Event Handlers
  const handleLapSelect = useCallback((index, isComparison) => {
    setSelectedLap(index);
    setSelectedTelemetry(isComparison ? 'comparison' : 'main');
  }, []);

  const handleComparisonToggle = useCallback((index, isComparison = false) => {
    setComparisonLaps(prev => {
      const lapNum = index + 1;
      
      // Se já existe, remove
      if (prev.some(l => l.lapNumber === lapNum && l.isComparison === isComparison)) {
        return prev.filter(l => !(l.lapNumber === lapNum && l.isComparison === isComparison));
      }
      
      // Se não existe e há espaço, adiciona
      if (prev.length < 3) {
        return [...prev, { lapNumber: lapNum, isComparison }];
      }
      
      // Se não há espaço, substitui o primeiro
      return [...prev.slice(1), { lapNumber: lapNum, isComparison }];
    });
  }, []);

  const handleChartHover = useCallback((index) => {
    setHoverPointIndex(index);
  }, []);

  // Renderização condicional para dados inválidos
  if (!hasValidData) {
    return (
      <div className="flex items-center justify-center h-full text-gray-400">
        <div className="text-center">
          <div className="text-lg mb-2">No telemetry data available</div>
          <div className="text-sm">Upload a telemetry file to begin analysis</div>
        </div>
      </div>
    );
  }

  // Renderização principal
  // Antes do return no TelemetryAnalyzer


  return (
    <div className="flex min-h-screen bg-gray-950">
      {/* Lista de voltas */}
      <div className="w-[300px] flex flex-col bg-gray-900/30 border-r border-gray-800/30">
        <SessionInfo 
          data={data} 
          onComparisonLoad={handleComparisonLoad}
        />
        
        <div className="flex-1 p-4 text-white">
          <LapList
            mainTelemetry={data}
            comparisonTelemetry={comparisonTelemetry}
            selectedLap={selectedLap}
            comparisonLaps={comparisonLaps}
            onLapSelect={handleLapSelect}
            onComparisonToggle={handleComparisonToggle}
            fastestLapIndex={fastestLapIndex}
            comparisonFastestLapIndex={comparisonFastestLapIndex}
          />
        </div>
      </div>

      {/* Área central - Conteúdo principal */}
      <div className="flex-1 flex flex-col min-w-0">
        {/* Tabs de navegação */}
        <div className="border-b border-gray-800">
          <div className="flex">
            <button
              onClick={() => setActiveTab('general')}
              className={`px-4 py-2 text-sm font-medium transition-colors ${
                activeTab === 'general' 
                  ? 'text-white bg-gray-800/50 border-b-2 border-blue-500' 
                  : 'text-gray-400 hover:text-white hover:bg-gray-800/30'
              }`}
            >
              GERAL
            </button>
            <button
              onClick={() => setActiveTab('setup')}
              className={`px-4 py-2 text-sm font-medium transition-colors ${
                activeTab === 'setup' 
                  ? 'text-white bg-gray-800/50 border-b-2 border-blue-500' 
                  : 'text-gray-400 hover:text-white hover:bg-gray-800/30'
              }`}
            >
              SETUP
            </button>
            <button
              onClick={() => setActiveTab('shocks')}
              className={`px-4 py-2 text-sm font-medium transition-colors ${
                activeTab === 'shocks' 
                  ? 'text-white bg-gray-800/50 border-b-2 border-blue-500' 
                  : 'text-gray-400 hover:text-white hover:bg-gray-800/30'
              }`}
            >
              SHOCKS
            </button>
            <button
              onClick={() => setActiveTab('recommendations')}
              className={`px-4 py-2 text-sm font-medium transition-colors ${
                activeTab === 'recommendations' 
                  ? 'text-white bg-gray-800/50 border-b-2 border-blue-500' 
                  : 'text-gray-400 hover:text-white hover:bg-gray-800/30'
              }`}
            >
              RECOMENDAÇÕES
            </button>
          </div>
        </div>

        {/* Container do conteúdo principal */}
        <div className="flex-1 relative group">
          <div className="flex">
            {/* Área dos gráficos */}
            <div className="flex-1 p-4 mr-[420px]">
              {activeTab === 'general' && (
                <div className="space-y-3">
                  {processedData.length > 0 && (
                    <>
                      <TelemetryChart
                        data={processedData}
                        comparisonData={processedComparisonData}
                        dataKey="speed"
                        config={CHART_CONFIGS.speed}
                        onHover={handleChartHover}
                      />
                      <TelemetryChart
                        data={processedData}
                        comparisonData={processedComparisonData}
                        dataKey="throttle"
                        config={CHART_CONFIGS.throttle}
                        onHover={handleChartHover}
                      />
                      <TelemetryChart
                        data={processedData}
                        comparisonData={processedComparisonData}
                        dataKey="brake"
                        config={CHART_CONFIGS.brake}
                        onHover={handleChartHover}
                      />
                      <TelemetryChart
                        data={processedData}
                        comparisonData={processedComparisonData}
                        dataKey="gear"
                        config={CHART_CONFIGS.gear}
                        onHover={handleChartHover}
                      />
                      <TelemetryChart
                        data={processedData}
                        comparisonData={processedComparisonData}
                        dataKey="steering"
                        config={CHART_CONFIGS.steering}
                        onHover={handleChartHover}
                      />
                      <TelemetryChart
                        data={processedData}
                        comparisonData={processedComparisonData}
                        dataKey="lineDiff"
                        config={CHART_CONFIGS.racingLine}
                        onHover={handleChartHover}
                      />
                      <TelemetryChart
                        data={processedData}
                        comparisonData={processedComparisonData}
                        dataKey="timeDelta"
                        config={CHART_CONFIGS.lapDelta}
                        onHover={handleChartHover}
                      />
                    </>
                  )}
                </div>
              )}

              {activeTab === 'setup' && (
                <SetupTab setupData={data?.sessionInfo?.CarSetup} />
              )}
              
              {activeTab === 'shocks' && (
                <ShocksTab 
                  data={data}
                  selectedLap={selectedLap} 
                  onHover={handleChartHover} 
                />
              )}

              {activeTab === 'recommendations' && (
                <RecommendationsTab 
                  telemetryData={data}
                  setupData={data?.sessionInfo?.CarSetup}
                  damperData={data?.laps?.[selectedLap]?.dampers}
                  sessionInfo={data?.sessionInfo}
                  selectedLap={selectedLap}
                />
              )}
            </div>

            {/* Mapa fixo */}
            {processedData?.length > 0 && (
              <div 
                className="mr-6 opacity-0 transition-opacity duration-500 delay-100 group-hover:opacity-100 fixed w-[420px] bg-gray-900/95 border-l border-gray-800/30 rounded-md"
                style={{ 
                  top: 'var(--telemetry-header-height, 500px)',
                  right: 0
                }}
              >
                <TrackMap
                  currentLapData={processedData}
                  comparisonData={processedComparisonData}
                  activePointIndex={hoverPointIndex}
                  sessionInfo={selectedTelemetry === 'main' ? data.sessionInfo : comparisonTelemetry?.sessionInfo}
                  trackOverlay={trackOverlay}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TelemetryAnalyzer;

