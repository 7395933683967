import React, { useState } from 'react';
import { Card } from '../ui/Card';
import { AlertCircle, Search } from 'lucide-react';
import { TRACKS_DOWNFORCE, DOWNFORCE_SPEED_RECOMMENDATIONS } from '../../utils/constants';
import FuelCalculator from './FuelCalculator';
import FOVCalculator from './FOVCalculator';

const TracksDownforceList = () => {
 const [searchTerm, setSearchTerm] = useState('');

 const getDownforceLevelColor = (level) => {
   const colors = {
     'High': 'bg-green-500/20 text-green-300',
     'Medium': 'bg-yellow-500/20 text-yellow-300',
     'Low': 'bg-red-500/20 text-red-300',
     'High/Medium': 'bg-emerald-500/20 text-emerald-300',
     'Low/Medium': 'bg-orange-500/20 text-orange-300'
   };
   return colors[level] || 'bg-gray-500/20 text-gray-300';
 };

 // Filtra as pistas baseado na pesquisa
 const filteredTracks = TRACKS_DOWNFORCE.filter(track =>
   track.name.toLowerCase().includes(searchTerm.toLowerCase())
 );

 // Se não houver pesquisa, mostra apenas 11 pistas
 const tracksToShow = searchTerm ? filteredTracks : TRACKS_DOWNFORCE.slice(0, 21);

 return (
   <Card className="bg-gray-800/50 my-12 backdrop-blur">
     <div className="p-6">
       <h2 className="text-xl font-bold text-white mb-6">Níveis de Downforce por Circuito</h2>
       
       <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
         {/* Lista de Circuitos */}
         <div className="space-y-4">
           {/* Barra de Pesquisa */}
           <div className="relative">
             <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
               <Search className="h-5 w-5 text-gray-400" />
             </div>
             <input
               type="text"
               placeholder="Pesquisar circuito..."
               value={searchTerm}
               onChange={(e) => setSearchTerm(e.target.value)}
               className="w-full pl-10 pr-4 py-2 bg-gray-700/50 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
             />
           </div>

           <div className="space-y-2">
             {tracksToShow.map((track) => (
               <div
                 key={track.name}
                 className="flex justify-between items-center p-2 rounded hover:bg-gray-700"
               >
                 <span className="text-gray-300">{track.name}</span>
                 <span className={`px-2 py-1 rounded text-sm ${getDownforceLevelColor(track.level)}`}>
                   {track.level}
                 </span>
               </div>
             ))}
           </div>

           {/* Mensagem quando não encontrar resultados */}
           {filteredTracks.length === 0 && (
             <div className="text-center text-gray-400 py-4">
               Nenhum circuito encontrado.
             </div>
           )}
         </div>

         {/* Lado Direito - Recomendações e Calculadora */}
         <div className="space-y-4">
           {/* Recomendações por Velocidade */}
           <div className="bg-gray-700/50 rounded-lg p-4">
             <h4 className="text-white font-medium mb-3">Recomendações por Velocidade</h4>
             <div className="space-y-2">
               {DOWNFORCE_SPEED_RECOMMENDATIONS.map((rec) => (
                 <div key={rec.speed} className="flex justify-between items-center">
                   <span className="text-gray-300">{rec.speed}</span>
                   <span className={`px-2 py-1 rounded text-sm ${
                     rec.level.includes("High") ? 'bg-green-500/20 text-green-300' :
                     rec.level.includes("Medium") ? 'bg-yellow-500/20 text-yellow-300' :
                     'bg-red-500/20 text-red-300'
                   }`}>
                     {rec.level}
                   </span>
                 </div>
               ))}
             </div>
           </div>

           {/* Nota Importante */}
           <div className="bg-gray-700/50 rounded-lg p-4">
             <div className="flex items-center space-x-2 mb-2">
               <AlertCircle className="h-5 w-5 text-yellow-500" />
               <h4 className="text-white font-medium">Nota Importante</h4>
             </div>
             <p className="text-sm text-gray-300">
               Outros fatores como design da pista (número de curvas de alta velocidade), altitude e condições ambientais
               também impactam sua decisão. Pistas em maior altitude e condições ambientais mais quentes favorecem mais
               downforce.
             </p>
           </div>

           {/* Calculadora de Combustível */}
           <FuelCalculator />
           <FOVCalculator />
         </div>
       </div>
     </div>
   </Card>
 );
};

export default TracksDownforceList;