import React, { useState } from 'react';
// Mude esta linha no AdminSetupsPanel.jsx
import { Card, CardHeader, CardTitle, CardContent } from '../../components/ui/Card';
import { useAuth } from '../../context/AuthContext';
import { database } from '../../services/firebase';
import { Upload, Calendar, Car } from 'lucide-react';

const AdminSetupsPanel = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);

  const seasons = [
    { id: '2025s1', name: '2025 Season 1' },
    { id: '2024s4', name: '2024 Season 4' },
  ];
  
  const weeks = Array.from({ length: 13 }, (_, i) => ({
    id: `week${i + 1}`,
    name: `Week ${i + 1}`
  }));
  
  const categories = [
    { id: 'gt3', name: 'GT3' },
    { id: 'gte', name: 'GTE' },
    { id: 'tcr', name: 'TCR' },
    { id: 'lmp2', name: 'LMP2' },
  ];

  const handleUpload = async () => {
    if (!selectedSeason || !selectedWeek || !selectedCategory || !uploadedFile) {
      alert('Por favor, selecione todos os campos e um arquivo');
      return;
    }

    setLoading(true);

    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const base64 = e.target.result;
        
        const fileData = {
          name: uploadedFile.name,
          size: uploadedFile.size,
          data: base64,
          uploadedBy: user.email,
          uploadedAt: Date.now(),
          season: selectedSeason.id,
          week: selectedWeek.id,
          category: selectedCategory.id,
          path: `${selectedSeason.id}/${selectedWeek.id}/${selectedCategory.id}`
        };

        await database.ref('setups').push(fileData);
        
        setUploadedFile(null);
        alert('Setup enviado com sucesso!');
      };

      reader.readAsDataURL(uploadedFile);
    } catch (error) {
      console.error('Erro no upload:', error);
      alert('Erro ao fazer upload do arquivo');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="bg-gray-800/50 backdrop-blur-sm">
        <CardHeader>
          <CardTitle className="flex items-center space-x-2">
            <Upload className="h-6 w-6" />
            <span>Gerenciador de Setups</span>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Lado Esquerdo - Seleções */}
            <div className="space-y-6">
              {/* Season */}
              <div className="space-y-2">
                <label className="flex items-center space-x-2 text-sm text-gray-300">
                  <Calendar className="h-4 w-4" />
                  <span>Season</span>
                </label>
                <div className="space-y-1">
                  {seasons.map((season) => (
                    <button
                      key={season.id}
                      onClick={() => setSelectedSeason(season)}
                      className={`w-full text-left px-4 py-2 rounded transition-colors ${
                        selectedSeason?.id === season.id
                          ? 'bg-blue-500/20 text-blue-400'
                          : 'hover:bg-gray-700/50 text-gray-300'
                      }`}
                    >
                      {season.name}
                    </button>
                  ))}
                </div>
              </div>

              {/* Week */}
              {selectedSeason && (
                <div className="space-y-2">
                  <label className="text-sm text-gray-300">Week</label>
                  <div className="space-y-1">
                    {weeks.map((week) => (
                      <button
                        key={week.id}
                        onClick={() => setSelectedWeek(week)}
                        className={`w-full text-left px-4 py-2 rounded transition-colors ${
                          selectedWeek?.id === week.id
                            ? 'bg-blue-500/20 text-blue-400'
                            : 'hover:bg-gray-700/50 text-gray-300'
                        }`}
                      >
                        {week.name}
                      </button>
                    ))}
                  </div>
                </div>
              )}

              {/* Category */}
              {selectedWeek && (
                <div className="space-y-2">
                  <label className="flex items-center space-x-2 text-sm text-gray-300">
                    <Car className="h-4 w-4" />
                    <span>Category</span>
                  </label>
                  <div className="space-y-1">
                    {categories.map((category) => (
                      <button
                        key={category.id}
                        onClick={() => setSelectedCategory(category)}
                        className={`w-full text-left px-4 py-2 rounded transition-colors ${
                          selectedCategory?.id === category.id
                            ? 'bg-blue-500/20 text-blue-400'
                            : 'hover:bg-gray-700/50 text-gray-300'
                        }`}
                      >
                        {category.name}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* Lado Direito - Upload */}
            <div className="space-y-6">
              <div className="border-2 border-dashed border-gray-700 rounded-lg p-6 text-center">
                <input
                  type="file"
                  id="setup-file"
                  accept=".sto"
                  className="hidden"
                  onChange={(e) => setUploadedFile(e.target.files[0])}
                />
                {uploadedFile ? (
                  <div className="space-y-4">
                    <p className="text-green-400">
                      Arquivo selecionado: {uploadedFile.name}
                    </p>
                    <button
                      onClick={() => setUploadedFile(null)}
                      className="text-red-400 hover:text-red-300 text-sm"
                    >
                      Remover arquivo
                    </button>
                  </div>
                ) : (
                  <label
                    htmlFor="setup-file"
                    className="cursor-pointer space-y-2 block"
                  >
                    <Upload className="mx-auto h-12 w-12 text-gray-400" />
                    <p className="text-gray-400">
                      Clique para selecionar um arquivo .sto
                    </p>
                  </label>
                )}
              </div>

              <button
                onClick={handleUpload}
                disabled={loading || !uploadedFile || !selectedSeason || !selectedWeek || !selectedCategory}
                className={`w-full px-4 py-2 rounded ${
                  loading || !uploadedFile || !selectedSeason || !selectedWeek || !selectedCategory
                    ? 'bg-gray-700 text-gray-400 cursor-not-allowed'
                    : 'bg-blue-600 text-white hover:bg-blue-700'
                }`}
              >
                {loading ? 'Enviando...' : 'Enviar Setup'}
              </button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default AdminSetupsPanel;