// src/components/layout/Header.jsx
import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { LogOut, Settings, Activity, User, Clock, Calendar, AlertTriangle, Download } from 'lucide-react';

const UserInfoDropdown = ({ user, subscription }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [timeNow, setTimeNow] = useState(new Date());
  const [inviteCode, setInviteCode] = useState('');
  const [isRenewing, setIsRenewing] = useState(false);
  const [renewError, setRenewError] = useState('');
  const dropdownRef = useRef(null);
  const { renewSubscription } = useAuth();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (isOpen) {
      const interval = setInterval(() => {
        setTimeNow(new Date());
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isOpen]);

  const formatTimeLeft = () => {
    if (!subscription?.expiresAt) return 'Não disponível';
    
    const now = new Date();
    const expiresAt = new Date(subscription.expiresAt);
    const timeLeft = expiresAt - now;
    
    if (timeLeft <= 0) return 'Expirado';
    
    // Calculando cada unidade de tempo
    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
    
    let timeString = '';
    
    // Formato mais compacto
    if (days > 0) timeString += `${days}d `;
    if (hours > 0) timeString += `${hours}h `;
    if (minutes > 0) timeString += `${minutes}m `;
    if (seconds > 0 || timeString === '') timeString += `${seconds}s`;
    
    return timeString.trim();
  };

  const formatExpirationDate = () => {
    if (!subscription?.expiresAt) return 'Não disponível';
    return new Date(subscription.expiresAt).toLocaleDateString('pt-BR');
  };

  const handleRenewal = async (e) => {
    e.preventDefault();
    if (!inviteCode.trim()) return;

    setIsRenewing(true);
    setRenewError('');

    try {
      await renewSubscription(inviteCode.trim());
      setInviteCode('');
      // Sucesso - mantenha o dropdown aberto para mostrar o novo tempo
    } catch (error) {
      setRenewError(error.message || 'Erro ao renovar assinatura');
    } finally {
      setIsRenewing(false);
    }
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 text-gray-300 cursor-pointer hover:text-gray-100 transition-colors"
      >
        <User size={16} className="text-gray-400" />
        <span className="text-sm font-medium">
          {user?.displayName || user?.email}
        </span>
      </div>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-72 bg-gray-800 rounded-lg shadow-lg py-2 z-50 border border-gray-700">
          <div className="px-4 py-2 border-b border-gray-700">
            <div className="flex items-center space-x-2">
              <User size={16} className="text-gray-400" />
              <span className="text-sm font-medium text-gray-200">
                {user?.displayName || user?.email}
              </span>
            </div>
          </div>
          
          <div className="px-4 py-2">
            <div className="space-y-2">
              <div className="flex items-center space-x-2 text-gray-300">
                <Clock size={16} className="text-gray-400" />
                <span className="text-sm">Tempo Restante:</span>
                <span className="text-sm font-medium text-blue-400">
                  {formatTimeLeft()}
                </span>
              </div>
              
              <div className="flex items-center space-x-2 text-gray-300">
                <Calendar size={16} className="text-gray-400" />
                <span className="text-sm">Expira em:</span>
                <span className="text-sm font-medium text-blue-400">
                  {formatExpirationDate()}
                </span>
              </div>

              {/* Formulário de Renovação */}
              <div className="mt-4 pt-4 border-t border-gray-700">
                <form onSubmit={handleRenewal} className="space-y-2">
                  <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-400">Código de Renovação</label>
                    <div className="flex space-x-2">
                      <input
                        type="text"
                        value={inviteCode}
                        onChange={(e) => setInviteCode(e.target.value)}
                        placeholder="Insira o código"
                        className="flex-1 px-3 py-1 text-sm bg-gray-900 border border-gray-700 rounded-md 
                                 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
                                 text-gray-200 placeholder-gray-500"
                      />
                      <button
                        type="submit"
                        disabled={isRenewing || !inviteCode.trim()}
                        className={`px-3 py-1 text-sm font-medium rounded-md 
                                  ${isRenewing || !inviteCode.trim()
                                    ? 'bg-gray-700 text-gray-400 cursor-not-allowed'
                                    : 'bg-blue-500 hover:bg-blue-600 text-white'
                                  } transition-colors`}
                      >
                        {isRenewing ? 'Renovando...' : 'Renovar'}
                      </button>
                    </div>
                    {renewError && (
                      <span className="text-xs text-red-400">{renewError}</span>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
const Header = ({ onTabChange }) => {
  const { user, logout, isAdmin, subscription } = useAuth();
  const [activeTab, setActiveTab] = useState('setup');
  const [showExpirationAlert, setShowExpirationAlert] = useState(false);

  useEffect(() => {
    if (subscription?.expiresAt) {
      const checkExpiration = () => {
        const now = new Date();
        const expiresAt = new Date(subscription.expiresAt);
        const timeLeft = expiresAt - now;
        const hoursLeft = timeLeft / (1000 * 60 * 60);

        if (hoursLeft <= 24 && hoursLeft > 0) {
          setShowExpirationAlert(true);
        } else {
          setShowExpirationAlert(false);
        }
      };

      // Verifica imediatamente
      checkExpiration();

      // Configura um intervalo para verificar a cada minuto
      const interval = setInterval(checkExpiration, 60000);

      return () => clearInterval(interval);
    }
  }, [subscription?.expiresAt]);

  const formatTimeLeft = () => {
    if (!subscription?.expiresAt) return 'Não disponível';
    
    const now = new Date();
    const expiresAt = new Date(subscription.expiresAt);
    const timeLeft = expiresAt - now;
    
    if (timeLeft <= 0) return 'Expirado';
    
    // Calculando cada unidade de tempo
    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    
    let timeString = '';
    
    // Formato mais compacto
    if (days > 0) timeString += `${days}d `;
    if (hours > 0) timeString += `${hours}h `;
    if (minutes > 0) timeString += `${minutes}m`;
    
    return timeString.trim();
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
    }
  };

  const switchTab = (tab) => {
    console.log('switchTab sendo chamado com:', tab); // Debug
    setActiveTab(tab);
    if (onTabChange) {
      onTabChange(tab); // Propaga a mudança para o componente pai
    }
    
    const adminPanel = document.getElementById('admin-panel');
    const mainContent = document.getElementById('main-content');
    const telemetryContent = document.getElementById('telemetry-content');
    const downloadsPanel = document.getElementById('downloads-panel');

    if (adminPanel && mainContent && telemetryContent && downloadsPanel) {
      adminPanel.style.display = tab === 'admin' ? 'block' : 'none';
      mainContent.style.display = tab === 'setup' ? 'block' : 'none';
      telemetryContent.style.display = tab === 'telemetry' ? 'block' : 'none';
      downloadsPanel.style.display = tab === 'downloads' ? 'block' : 'none';
    }
  };

  return (
    <header className="bg-gray-800/50 backdrop-blur-sm shadow-lg relative z-50">
      {/* Ajustado o padding para remover espaço extra */}
      <div className="container mx-auto px-4 py-2"> {/* Reduzido o py-3 para py-2 */}
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            {/* Logo ajustado */}
            <div 
              className="flex items-center cursor-pointer group h-12" // Adicionado altura fixa
              onClick={() => switchTab('setup')}
            >
              {/* Meia engrenagem */}
              <div className="text-blue-500 mr-1 overflow-hidden" style={{ width: '12px' }}>
                <svg 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2.5" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                  className="group-hover:rotate-45 transition-transform duration-300"
                >
                  <circle cx="12" cy="12" r="3"></circle>
                  <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                </svg>
              </div>
              <span className="text-lg font-medium text-white">
                <span className="text-white">i</span>Setup
              </span>
            </div>

            {/* Navegação */}
            <nav className="flex items-center space-x-4 relative z-50">
              <button
                onClick={() => switchTab('setup')}
                className={`flex items-center space-x-2 px-3 py-1.5 rounded-lg hover:bg-blue-500/20 transition-colors text-sm font-medium cursor-pointer ${
                  activeTab === 'setup' ? 'bg-blue-500/10 text-blue-400' : 'text-gray-400 hover:text-gray-200'
                }`}
              >
                <Settings size={16} />
                <span>Setup</span>
              </button>

              <button
                onClick={() => switchTab('telemetry')}
                className={`flex items-center space-x-2 px-3 py-1.5 rounded-lg hover:bg-blue-500/20 transition-colors text-sm font-medium cursor-pointer ${
                  activeTab === 'telemetry' ? 'bg-blue-500/10 text-blue-400' : 'text-gray-400 hover:text-gray-200'
                }`}
              >
                <Activity size={16} />
                <span>Telemetria</span>
              </button>

              {/* Botão de Compra */}
              <a
                href="https://discord.com/channels/1300408362374266882/1301917926156271637" 
                target="_blank"
                rel="noopener noreferrer"
                className="flex text-blue-400 items-center space-x-2 px-3 py-1.5 rounded-lg hover:bg-blue-500/20 transition-colors text-sm font-medium cursor-pointer 
                         'bg-blue-500/10 text-blue-400' : 'text-gray-400 hover:text-gray-200'"
              >
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zM9 7v10m6-10v10" />
                  <line x1="9" y1="12" x2="15" y2="12" />
                </svg>
                <span>Buy</span>
              </a>
              
              <button
  onClick={() => {
    console.log('Clique no botão Downloads'); // Debug
    switchTab('downloads');
  }}
  className={`flex items-center space-x-2 px-3 py-1.5 rounded-lg hover:bg-blue-500/20 transition-colors text-sm font-medium cursor-pointer ${
    activeTab === 'downloads' ? 'bg-blue-500/10 text-blue-400' : 'text-gray-400 hover:text-gray-200'
  }`}
>
  <Download size={16} />
  <span>Downloads</span>
</button>

              {/* Badge de Admin */}
              {isAdmin === true && (
                <button
                  onClick={() => switchTab('admin')}
                  className={`flex items-center space-x-2 px-3 py-1.5 rounded-lg hover:bg-blue-500/20 transition-colors text-sm font-medium cursor-pointer ${
                    activeTab === 'admin' ? 'bg-blue-500/10 text-blue-400' : 'text-gray-400 hover:text-gray-200'
                  }`}
                >
                  <Settings size={16} />
                  <span>Admin</span>
                </button>
              )}
            </nav>
          </div>

          {/* Área central com alerta quando necessário */}
          {showExpirationAlert && (
            <div className="flex-1 flex justify-end">
              <div className="px-4 py-1 rounded-md bg-red-500/10 backdrop-blur-sm flex items-center space-x-2">
                <AlertTriangle size={14} className="text-red-400" />
                <span className="text-sm text-gray-200">
                  Tempo restante: {formatTimeLeft()}
                </span>
              </div>
            </div>
          )}
          
          <div className="flex items-center space-x-6">
            {/* Info do usuário com dropdown */}
            <UserInfoDropdown user={user} subscription={subscription} />

            {/* Botão de logout */}
            <button 
              onClick={handleLogout}
              className="flex items-center space-x-2 px-3 py-1.5 text-gray-400 hover:text-gray-200 transition-colors cursor-pointer"
            >
              <LogOut size={16} />
              <span className="text-sm font-medium">Sair</span>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

