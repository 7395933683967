import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { database } from '../../services/firebase';
import { Upload, Download, Calendar, Car } from 'lucide-react';

const DownloadsPage = () => {
  const { user, isAdmin } = useAuth();
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [availableCategories, setAvailableCategories] = useState([]);

  const seasons = [
    { id: '2025s1', name: '2025 Season 1' },
    { id: '2024s4', name: '2024 Season 4' },
  ];
  
  const weeks = Array.from({ length: 13 }, (_, i) => ({
    id: `week${i + 1}`,
    name: `Week ${i + 1}`
  }));
  
  const categories = [
    { id: 'gt3', name: 'GT3' },
    { id: 'gte', name: 'GTE' },
    { id: 'tcr', name: 'TCR' },
    { id: 'lmp2', name: 'LMP2' },
  ];

  // Carrega as categorias disponíveis quando uma semana é selecionada
  useEffect(() => {
    if (selectedSeason && selectedWeek) {
      setLoading(true);
      const path = `${selectedSeason.id}/${selectedWeek.id}`;
      
      database.ref('setups')
        .orderByChild('path')
        .startAt(path)
        .endAt(path + '\uf8ff')
        .once('value')
        .then((snapshot) => {
          const data = snapshot.val() || {};
          const cats = [...new Set(Object.values(data).map(item => item.category))];
          const availableCats = categories.filter(cat => cats.includes(cat.id));
          setAvailableCategories(availableCats);
        })
        .catch(error => {
          console.error('Erro ao carregar categorias:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setAvailableCategories([]);
    }
  }, [selectedSeason, selectedWeek]);

  // Carrega os arquivos quando uma categoria é selecionada
  useEffect(() => {
    if (selectedSeason && selectedWeek && selectedCategory) {
      setLoading(true);
      const path = `${selectedSeason.id}/${selectedWeek.id}/${selectedCategory.id}`;
      
      database.ref('setups')
        .orderByChild('path')
        .equalTo(path)
        .once('value')
        .then((snapshot) => {
          const data = snapshot.val() || {};
          const setupsList = Object.entries(data).map(([id, value]) => ({
            id,
            ...value
          }));
          setFiles(setupsList);
        })
        .catch(error => {
          console.error('Erro ao carregar arquivos:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setFiles([]);
    }
  }, [selectedSeason, selectedWeek, selectedCategory]);

  const handleDownload = async (fileData) => {
    try {
      // Convert base64 to blob
      const byteCharacters = atob(fileData.data.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/octet-stream' });
      
      // Create download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileData.name;
      document.body.appendChild(a);
      a.click();
      
      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Erro no download:', error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="container mx-auto px-4">
        {/* Breadcrumb */}
        <div className="mb-6">
          <h1 className="text-2xl font-bold text-white mb-2">Downloads</h1>
          <div className="flex items-center space-x-2 text-sm text-gray-400">
            <span>{selectedSeason?.name}</span>
            {selectedSeason && (
              <>
                <span className="text-gray-600">&gt;</span>
                <span>{selectedWeek?.name}</span>
              </>
            )}
            {selectedWeek && (
              <>
                <span className="text-gray-600">&gt;</span>
                <span>{selectedCategory?.name}</span>
              </>
            )}
          </div>
        </div>

        {/* Grid de Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Seasons Card */}
          <div className="bg-[#1a2234] rounded-lg p-4">
            <div className="flex items-center gap-2 mb-4 text-white">
              <Calendar size={18} />
              <h2 className="font-semibold">Seasons</h2>
            </div>
            <div className="space-y-1">
              {seasons.map((season) => (
                <button
                  key={season.id}
                  onClick={() => {
                    setSelectedSeason(season);
                    setSelectedWeek(null);
                    setSelectedCategory(null);
                  }}
                  className={`w-full text-left px-4 py-2 rounded transition-colors ${
                    selectedSeason?.id === season.id
                      ? 'bg-blue-500/20 text-blue-400'
                      : 'text-gray-400 hover:bg-[#1e293b] hover:text-gray-200'
                  }`}
                >
                  {season.name}
                </button>
              ))}
            </div>
          </div>

          {/* Weeks Card */}
          {selectedSeason && (
            <div className="bg-[#1a2234] rounded-lg p-4">
              <div className="flex items-center gap-2 mb-4 text-white">
                <h2 className="font-semibold">Weeks</h2>
              </div>
              <div className="space-y-1">
                {weeks.map((week) => (
                  <button
                    key={week.id}
                    onClick={() => {
                      setSelectedWeek(week);
                      setSelectedCategory(null);
                    }}
                    className={`w-full text-left px-4 py-2 rounded transition-colors ${
                      selectedWeek?.id === week.id
                        ? 'bg-blue-500/20 text-blue-400'
                        : 'text-gray-400 hover:bg-[#1e293b] hover:text-gray-200'
                    }`}
                  >
                    {week.name}
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* Categories Card */}
          {selectedWeek && (
            <div className="bg-[#1a2234] rounded-lg p-4">
              <div className="flex items-center gap-2 mb-4 text-white">
                <Car size={18} />
                <h2 className="font-semibold">Categories</h2>
              </div>
              {loading ? (
                <div className="flex justify-center py-4">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
                </div>
              ) : availableCategories.length > 0 ? (
                <div className="space-y-1">
                  {availableCategories.map((category) => (
                    <button
                      key={category.id}
                      onClick={() => setSelectedCategory(category)}
                      className={`w-full text-left px-4 py-2 rounded transition-colors ${
                        selectedCategory?.id === category.id
                          ? 'bg-blue-500/20 text-blue-400'
                          : 'text-gray-400 hover:bg-[#1e293b] hover:text-gray-200'
                      }`}
                    >
                      {category.name}
                    </button>
                  ))}
                </div>
              ) : (
                <p className="text-center py-4 text-gray-400">
                  No categories available for this selection
                </p>
              )}
            </div>
          )}
        </div>

        {/* Files Section */}
        {selectedCategory && (
          <div className="mt-8 bg-[#1a2234] rounded-lg p-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-white font-semibold">Available Files</h2>
            </div>
            
            {loading ? (
              <div className="flex justify-center py-8">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
              </div>
            ) : files.length === 0 ? (
              <p className="text-center py-8 text-gray-400">
                No files available for this selection
              </p>
            ) : (
              <div className="space-y-2">
                {files.map((file) => (
                  <div
                    key={file.id}
                    className="flex items-center justify-between p-4 bg-[#1e293b] rounded transition-all duration-300 hover:bg-[#1e293b]/80"
                  >
                    <div>
                      <p className="text-white">{file.name}</p>
                      <p className="text-sm text-gray-400">
                        Uploaded by {file.uploadedBy} on{' '}
                        {new Date(file.uploadedAt).toLocaleDateString()}
                      </p>
                    </div>
                    <button
                      onClick={() => handleDownload(file)}
                      className="flex items-center gap-2 px-3 py-1.5 text-green-400 hover:text-green-300 transition-colors"
                      disabled={loading}
                    >
                      <Download size={16} />
                      <span>Download</span>
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DownloadsPage;